import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import TodayIcon from '@material-ui/icons/Today';
import Comment from './Comment';
import Recette from './Recette';
import RecetteLinks from './RecetteLinks';
import Container from '@material-ui/core/Container';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 'auto',
    padding: '10px 12px',
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

class RandomRecette extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idrecette: '',
      nom: '',
      temps: '',
      tempsGlobal: '',
      description: '',
      ingredients: '',
      achats: '',
      type: '',
      source: '',
      comments: {}
    }
    if (this.props.location) {
      const urlvalues = queryString.parse(this.props.location.search);
      if (urlvalues.idrecette) {
        new Promise((resolve, reject) => {
          this.page = this.props.page
          let url = process.env.REACT_APP_SRV_URL
          url += '/recettes/'
          url += urlvalues.idrecette
          fetch(url)
            .then(response => response.json())
            .then(result => {
              this.setState({ nom: result.rows[0].nom });
              this.setState({ temps: result.rows[0].temps });
              this.setState({ tempsGlobal: result.rows[0].tempsGlobal });
              this.setState({ description: result.rows[0].description });
              this.setState({ ingredients: result.rows[0].ingredients });
              this.setState({ achats: result.rows[0].achats });
              this.setState({ type: result.rows[0].type });
              this.setState({ source: result.rows[0].source });
              this.setState({ ingsListe: this.splitIngredients(result.rows[0].ingredients ) });
              this.setState({ comments: result.comments });
            }).catch(err => console.log(err))
        });
      } else  {
        new Promise((resolve, reject) => {
          let url = process.env.REACT_APP_SRV_URL
          url += '/recettes/random'
          fetch(url)
            .then(response => response.json())
            .then(result => {
              this.setState({ idrecette: result.rows[0].idrecette });
              this.setState({ nom: result.rows[0].nom });
              this.setState({ temps: result.rows[0].temps });
              this.setState({ tempsGlobal: result.rows[0].tempsGlobal });
              this.setState({ description: result.rows[0].description });
              this.setState({ ingredients: result.rows[0].ingredients });
              this.setState({ achats: result.rows[0].achats });
              this.setState({ type: result.rows[0].type });
              this.setState({ source: result.rows[0].source });
              this.setState({ comments: result.comments });
              this.setState({ ingsListe: this.splitIngredients(result.rows[0].ingredients ) });
            }).catch(err => console.log(err))
        });
      }
    }

    this.updateRec = this.updateRec.bind(this);
    this.addToPlanning = this.addToPlanning.bind(this);
  }

  splitIngredients(ings) {
    var data = '[';
    var inglist = ings.split("+");
    // for each ingredients
    inglist.forEach(function(item) {
        var ing = item.split(")");
        var qtt = ing[0].split("(");
        data += '{ "ingredient" : "'+ ing[1].trim()+'", "quantite" : "'+qtt[1]+'" },'
    })
    data += ']'
    return eval(data);
  }

  addToPlanning() {
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/planning?'
      url += 'user=' + sessionStorage.getItem('user')
      url += '&position=stock'
      url += '&id=' + this.state.idrecette
      fetch(url, { method: 'PUT' , headers: { "Authorization": "Bearer "+sessionStorage.getItem('token') }})
        .then(response => response.json())
        .then(result => {
        }).catch(err => console.log(err))
    });
  }

  updateRec() {
    window.location.href = "/recettes/random";
  }

  render() {
    return (
      <React.Fragment>
        <Container className={useStyles.container}>
          <IconButton color="primary" className={useStyles.button} aria-label="Rafraichir" onClick={this.updateRec} size="small"  >
            <ReplayIcon />
          </IconButton>
          <IconButton color="primary" className={useStyles.button} aria-label="Ajouter au planning" onClick={this.addToPlanning} size="small">
            <TodayIcon />
          </IconButton>
          <Recette id={this.state.idrecette} ingsListe={this.state.ingsListe} nom={this.state.nom} temps={this.state.temps} tempsGlobal={this.state.tempsGlobal} ingredients={this.state.ingredients} source={this.state.source} type={this.state.type} achats={this.state.achats} description={this.state.description} />
          <RecetteLinks id={this.state.idrecette}/>
            <br />
          <Comment page="/recettes/random" comments={this.state.comments} recId={this.state.idrecette} oldpage="random"/>
        </Container>
      </React.Fragment>
    );
  }
}
export default RandomRecette;