import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  }));

class StatsView extends Component {
    constructor(props) {
        super(props);
        this.state = {nbr: ''}
        new Promise((resolve, reject) => {
            this.page = this.props.page
            let url = process.env.REACT_APP_SRV_URL
            url += '/recettes/nbr'
            fetch(url)
              .then(response => response.json())
              .then(result => {
                this.setState({ nbr: result.nbr });
              }).catch(err => console.log(err))
          });

    }
    render() {
      if (this.state.nbr !== '') {
        return (
            <React.Fragment>
                <Container className={useStyles.container}>
                <h2>Status OK (nbr recettes: {this.state.nbr})</h2>
                </Container>
            </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
              <Container className={useStyles.container}>
              <h2>Status KO </h2>
              </Container>
          </React.Fragment>
        );
      }
    }
}

export default StatsView;