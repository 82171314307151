import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { ValidatorForm} from 'react-material-ui-form-validator';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable from 'material-table';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fabBottom: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#ff9100',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddIngInListecourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogEdit: false,
      selectedings: {}
    }
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleOpenDialog() {
    this.setState({
      openDialogEdit: true
    });
  }



  handleCloseDialog() {
    this.setState({
      openDialogEdit: false
    });
  }

  handleSubmit = (event) => {
    for(var it in this.state.selectedings){
      new Promise((resolve, reject) => {
        let url = process.env.REACT_APP_SRV_URL
        url += '/listecourse/'
        fetch(url, { method: 'POST',
          headers: {"Content-type": "application/json; charset=UTF-8",
            "Authorization": "Bearer "+sessionStorage.getItem('token')
          },
          body: '{"user":"'+sessionStorage.getItem('user')+'","labelIng":"'+this.state.selectedings[it].ingredient+'"}' })
          .then(response => response.json())
          .then(result => {
            resolve({
            })
          })
      })
    }
    this.handleCloseDialog();
  }

  render() {
    return (
      <React.Fragment>
          <Button color="primary" aria-label="add" onClick={this.handleOpenDialog} size="small">
            <AddShoppingCartIcon />
          </Button>
          <Container className={useStyles.container}>
          <Dialog id={"dialogaddingliste"} open={this.state.openDialogEdit} onClose={this.handleOpenDialog} TransitionComponent={Transition} >
            <div style={{ width: 500 }}>
              <DialogTitle id="customized-dialog-title" >
                Ajout des ingredients a la liste
                    </DialogTitle>
              <ValidatorForm className={useStyles.root} ref="form" onSubmit={this.handleSubmit} autoComplete="off" onError={errors => console.log(errors)}>
                <DialogContent dividers>
                  <div>
                  <MaterialTable
                    columns={[
                      {
                        title: 'Selectionne tous les ingredients:', field: 'ingredient',
                      },
                    ]}
                    data={
                      this.props.ingsListe
                    }
                    options={{
                      selection: true,
                      search: false,
                      filtering: false,
                      sorting: false,
                      paging: false,
                      showTitle:false,
                      headerStyle: {
                        backgroundColor: '#115293',
                        color: '#FFF',
                    }
                    }}
                    onSelectionChange={(rows) => 
                      this.setState({
                        selectedings: rows
                      })
                    }
                  />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialog} color="primary" variant="contained" startIcon={<DeleteIcon />}>
                    Annuler
                    </Button>
                  <Button type="submit" color="primary" variant="contained" startIcon={<SaveIcon />}>
                    Valider
                    </Button>
                </DialogActions>
              </ValidatorForm>
            </div>
          </Dialog>
        </Container>
      </React.Fragment>
    );
  }
}
export default AddIngInListecourse;