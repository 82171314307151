import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import LinkTab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import RecettesTable from './RecettesTable'
import Planning from './Planning'
import RandomRecette from './RandomRecette'
import StockTable from './StockTable'
import ListecourseTable from './ListecourseTable'
import AdminTable from './AdminTable'
import IngredientsTable from './IngredientsTable'
import ToolBarRec from './ToolBarRec'
import RecetteView from './RecetteView'
import Healthcheck from './Healthcheck'
import StatsView from './StatsView.jsx'
import withAuth from './withAuth';
import CaveTable from './CaveTable';
import { Route , BrowserRouter} from 'react-router-dom';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
  },
}));

function setPath(path) {
  if (path === "/recettes") {
    return 0;
  } else if (path === "/recettes/planning") {
    return 1;
  } else if (path === "/recettes/stock") {
    return 2;
  } else if (path === "/recettes/random") {
    return 3;
  } else if (path === "/recettes/ingredients") {
    return 4;
  } else if (path === "/recettes/courses") {
    return 5;
  } else if (path === "/recettes/cave") {
    return 6;
  } else if (path === "/recettes/admin") {
    return 7;
  } else if (path === "/recettes/stats") {
    return 8;
  } else if (path === "/recettes/recette") {
    return 9;
  } else if (path === "/recettes/healthcheck") {
    return 10;
  } else {
    return 0;
  }
}

function Acceuil() {
  const classes = useStyles();
  const [value, setValue] = React.useState(setPath(window.location.pathname));

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  

  return (
    <div className={classes.root}>
      <AppBar position="static" >
        <ToolBarRec/>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <LinkTab label="Recettes" />
          <LinkTab label="Planning" href="/recettes/planning" />
          <LinkTab label="Stock" href="/recettes/stock"  />
          <LinkTab label="Recette du jour" href="/recettes/random"  />
          <LinkTab label="ingredients" href="/recettes/ingredients" />
          <LinkTab label="Courses" href="/recettes/courses" />
          <LinkTab label="cave" href="/recettes/cave" />
          <LinkTab label="Admin" href="/recettes/admin" />
          <LinkTab label="Statistiques" href="/recettes/stats" />
          <LinkTab label="" href="/recettes/recette" disabled/>
          <LinkTab label="" href="/recettes/healthcheck" disabled/>
        </Tabs>
      </AppBar>
      <BrowserRouter  basename="/recettes">
        {value === 0 && <Route path="/" component={withAuth(RecettesTable)} /> }
        {value === 1 && <Route path="/planning" component={withAuth(Planning)} /> }
        {value === 2 && <Route path="/stock" component={withAuth(StockTable)} /> }
        {value === 3 && <Route path="/random" component={withAuth(RandomRecette)} /> }
        {value === 4 && <Route path="/ingredients" component={withAuth(IngredientsTable)} />}
        {value === 5 && <Route path="/courses" component={withAuth(ListecourseTable)} />}
        {value === 6 && <Route path="/cave" component={withAuth(CaveTable)} />}
        {value === 7 && <Route path="/admin" component={withAuth(AdminTable)} />}
        {value === 8 && <Route path="/stats" component={withAuth(StatsView)} />}
        {value === 9 && <Route path="/recette" component={withAuth(RecetteView)} />}
        {value === 10 && <Route path="/healthcheck" component={Healthcheck} />}
      </BrowserRouter>
    </div>
  );
}

export default Acceuil;