import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Login from './login'

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }
    componentDidMount() {
      if (sessionStorage.getItem('token')) {
        this.setState({ loading: false,redirect: false });
      } else {
        new Promise((resolve, reject) => {
          let url =  process.env.REACT_APP_SRV_URL
          url +=   '/checkToken'
          url += '?token=' + (sessionStorage.getItem('token') || '')
          fetch(url, { 
              method: 'GET',
            })
          .then(res => {
            if (res.status === 200) {
              this.setState({ loading: false });
            } else {
              const error = new Error(res.error);
              throw error;
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({ loading: false, redirect: true });
          });
        })
      }
    }
    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return  <React.Fragment><Typography component="div" style={{ padding: 8 * 3 }}><Login /></Typography></React.Fragment>
      }
      return (
        <React.Fragment><Typography component="div" style={{ padding: 8 * 3 }}> <ComponentToProtect {...this.props} /></Typography></React.Fragment>
      );
    }
  }
}

