import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import Slide from '@material-ui/core/Slide';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {escape} from 'html-escaper';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fabBottom: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CommentEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogEdit: false,
      commentaire: ''
    }
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleOpenDialog() {
    this.setState({
      openDialogEdit: true
    });
  }

  handleChange = (event) => {
    this.setState({ commentaire: event.target.value });
}


  handleCloseDialog() {
    this.setState({
      openDialogEdit: false
    });
  }

  handleSubmit = (event) => {
    let url = this.props.page + "?idrecette=" + this.props.recId +"&page=" + this.props.oldpage
    if (this.props.search) {
      url += "&search="+ this.props.search
    }
    if (this.props.ings) {
      url += "&ings="+ this.props.ings
    }
    if (this.props.seachat) {
      url += "&seachat="+this.props.seachat
    }
    if (this.props.senom) {
      url += "&senom="+this.props.senom
    }
    if (this.props.setype) {
      url += "&setype="+this.props.setype
    }
    if (this.props.senote) {
      url += "&senote="+this.props.senote
    }
    if (this.props.sesource) {
      url += "&sesource="+this.props.sesource
    }
    if (this.props.setps) {
      url += "&setps="+this.props.setps
    }
    if (this.props.sepg) {
      url += "&sepg="+this.props.sepg
    }
    new Promise((resolve, reject) => {
      let apiurl = process.env.REACT_APP_SRV_URL
      apiurl += '/recettes/comment'
      apiurl += '/' + this.props.recId
      fetch(apiurl, { method: 'PUT', 
          headers: { "Content-type": "application/json; charset=UTF-8" ,
            "Authorization": "Bearer "+sessionStorage.getItem('token')
          }, 
          body: '{"user":"' + sessionStorage.getItem('user') + '","commentaire":"' + escape(event.target.commentaire.value) + '","note":"' + event.target.note.value + '"}' })
        .then(response => response.json())
        .then(result => {
          window.location.href = url ;
          this.handleCloseDialog();
        }).catch(err => console.log(err))
    })
  }

  render() {
    return (
      <React.Fragment>
        <Container className={useStyles.container}>
          <Fab color="primary" aria-label="add" className={useStyles.fabBottom} onClick={this.handleOpenDialog} size="small">
            <AddIcon />
          </Fab>
          <Dialog id={"dialogComment"} open={this.state.openDialogEdit} onClose={this.handleOpenDialog} TransitionComponent={Transition} >
            <div style={{ width: 500 }}>
              <DialogTitle id="customized-dialog-title" >
                Ajout d'un commentaire
                    </DialogTitle>
              <ValidatorForm className={useStyles.root} ref="form" onSubmit={this.handleSubmit} autoComplete="off" onError={errors => console.log(errors)}>
                <DialogContent dividers>
                  <div>
                  <TextField id="idrecette" name="idrecette" value={this.props.recId} style={{display: 'none'}} />
                  <TextField id="page" name="page" value={this.props.oldpage } style={{display: 'none'}} />
                  <TextField id="search" name="search" value={this.props.search } style={{display: 'none'}} />
                  <TextField id="ings" name="ings" value={this.props.ings } style={{display: 'none'}} />
                  <TextField id="seachat" name="seachat" value={this.props.seachat } style={{display: 'none'}} />
                  <TextField id="senom" name="senom" value={this.props.senom } style={{display: 'none'}} />
                  <TextField id="setype" name="setype" value={this.props.setype } style={{display: 'none'}} />
                  <TextField id="senote" name="senote" value={this.props.senote } style={{display: 'none'}} />
                  <TextField id="sesource" name="sesource" value={this.props.sesource } style={{display: 'none'}} />
                  <TextField id="setps" name="setps" value={this.props.setps } style={{display: 'none'}} />
                    <TextValidator
                      id="commentaire"
                      label="Commentaire"
                      name="commentaire"
                      onChange={this.handleChange}
                      multiline
                      rows="10"
                      fullWidth
                      value={this.state.commentaire}
                      validators={['required','maxStringLength:2048']}
                      errorMessages={['le commentaire est obligatoire','1024 charactères maximum']}
                      variant="filled"
                    />
                    <Rating name="note" max={3} />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleCloseDialog} color="primary" variant="contained" startIcon={<DeleteIcon />}>
                    Annuler
                    </Button>
                  <Button type="submit" color="primary" variant="contained" startIcon={<SaveIcon />}>
                    Valider
                    </Button>
                </DialogActions>
              </ValidatorForm>
            </div>
          </Dialog>
        </Container>
      </React.Fragment>
    );
  }
}
export default CommentEdit;