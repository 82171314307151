import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TodayIcon from '@material-ui/icons/Today';
import Comment from './Comment';
import Recette from './Recette';
import RecetteEdit from './RecetteEdit';
import RecetteLinks from './RecetteLinks';
import Container from '@material-ui/core/Container';
import queryString from 'query-string';
import CloseIcon from '@material-ui/icons/Close';
import {escape} from 'html-escaper';
import AddIngInListecourse from './AddIngInListecourse';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
}));

class RecetteView extends Component {
  constructor(props) {
    super(props);
    if (this.props.location) {
      const urlvalues = queryString.parse(this.props.location.search);
      this.state = {
        idrecette: urlvalues.idrecette,
        nom: '',
        temps: '',
        tempsGlobal: '',
        description: '',
        ingredients: '',
        achats: '',
        type: '',
        saison: '',
        source: '',
        comments: {},
        page: urlvalues.page,
        search: urlvalues.search,
        ings: urlvalues.ings,
        seachat: urlvalues.seachat,
        senom: urlvalues.senom,
        setype: urlvalues.setype,
        sesaison: urlvalues.sesaison,
        senote: urlvalues.senote,
        status: urlvalues.status, 
        sesource: urlvalues.sesource,
        setps: urlvalues.setps,
        sepg: urlvalues.sepg
      }
      if (this.state.status !== "add") {
        new Promise((resolve, reject) => {
          this.page = this.props.page
          let url = process.env.REACT_APP_SRV_URL
          url += '/recettes/'
          url += urlvalues.idrecette
          fetch(url)
            .then(response => response.json())
            .then(result => {
              this.setState({ nom: result.rows[0].nom });
              this.setState({ temps: result.rows[0].temps });
              this.setState({ tempsGlobal: result.rows[0].tempsGlobal });
              this.setState({ description: result.rows[0].description });
              this.setState({ ingredients: result.rows[0].ingredients });
              this.setState({ achats: result.rows[0].achats });
              this.setState({ type: result.rows[0].type });
              this.setState({ saison: result.rows[0].saison });
              this.setState({ source: result.rows[0].source });
              this.setState({ comments: result.comments });
              this.setState({ ingsListe: this.splitIngredients(result.rows[0].ingredients) });
            }).catch(err => console.log(err))
        });
      }
    }
    this.addToPlanning = this.addToPlanning.bind(this);
    this.closeRec = this.closeRec.bind(this);
  }

    splitIngredients(ings) {
    ings = escape(ings)
    console.log(ings)
    var data = '[';
    var inglist = ings.split("+");
    // for each ingredients
    inglist.forEach(function (item) {
      var ing = item.split(")");
      var qtt = ing[0].split("(");
      data += '{ "ingredient" : "' + ing[1].trim() + '", "quantite" : "' + qtt[1] + '" },'
    })
    data += ']'
    return eval(data);
  }

  addToPlanning() {
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/planning?'
      url += 'user=' + sessionStorage.getItem('user')
      url += '&position=stock'
      url += '&id=' + this.state.idrecette
      fetch(url, { method: 'PUT', headers: { "Authorization": "Bearer "+sessionStorage.getItem('token') } })
        .then(response => response.json())
        .then(result => {
        }).catch(err => console.log(err))
    });
  } url

  closeRec() {
    let url = "/recettes";
    if (this.state.page === "planning") {
      url += "/" + this.state.page;
      window.location.href = url;
    } else {
      let isFirst = true;
      if (this.state.search) {
        url += "?search=" + this.state.search
        isFirst = false
      }
      if (this.state.ings) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "ings=" + this.state.ings
        isFirst = false
      }
      if (this.state.seachat) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "seachat=" + this.state.seachat
        isFirst = false
      }
      if (this.state.senom) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "senom=" + this.state.senom
        isFirst = false
      }
      if (this.state.setype) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "setype=" + this.state.setype
        isFirst = false
      }
      if (this.state.sesaison) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "sesaison=" + this.state.sesaison
        isFirst = false
      }
      if (this.state.senote) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "senote=" + this.state.senote
        isFirst = false
      }
      if (this.state.sesource) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "sesource=" + this.state.sesource
        isFirst = false
      }
      if (this.state.setps) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "setps=" + this.state.setps
        isFirst = false
      }
      if (this.state.sepg) {
        let start = "&"
        if (isFirst) {
          start = "?"
        }
        url += start + "sepg=" + this.state.sepg
      }
      window.location.href = url
    }
  }

  render() {
    if (this.state.status === "edit") {
      return (
        <React.Fragment>
          <Container className={useStyles.container} >
            <div>
            <IconButton id={"close" + this.props.id} color="primary" className={useStyles.button} aria-label="Voir recette" onClick={this.closeRec} >
              <CloseIcon />
            </IconButton>
            </div>
            <RecetteEdit id={this.state.idrecette} status={this.state.status} ingsListe={this.state.ingsListe} nom={this.state.nom} temps={this.state.temps} tempsGlobal={this.state.tempsGlobal} ingredients={this.state.ingredients} source={this.state.source} type={this.state.type} saison={this.state.saison} achats={this.state.achats} description={this.state.description} search={this.state.search} seings={this.state.ings} seachat={this.state.seachat} senom={this.state.senom} setype={this.state.setype} senote={this.state.senote} sesource={this.state.sesource} setps={this.state.setps} sepg={this.state.sepg} />
          </Container>
        </React.Fragment>
      );
    } else if (this.state.status === "add") {
      return (
        <React.Fragment>
          <Container className={useStyles.container}>
            <IconButton id={"close" + this.props.id} color="primary" className={useStyles.button} aria-label="Voir recette" onClick={this.closeRec} >
              <CloseIcon />
            </IconButton>
            <RecetteEdit status={this.state.status} search={this.state.search} seings={this.state.ings} seachat={this.state.seachat} senom={this.state.senom}  setype={this.state.setype} sesaison={this.state.sesaison} senote={this.state.senote} sesource={this.state.sesource} setps={this.state.setps} sepg={this.state.sepg}/>
          </Container>
        </React.Fragment>
         );
    } else {
      return (
        <React.Fragment>
          <Container className={useStyles.container}>
            <IconButton id={"close" + this.props.id} color="primary" className={useStyles.button} aria-label="Voir recette" onClick={this.closeRec} >
              <CloseIcon />
            </IconButton>
            <IconButton color="primary" className={useStyles.button} aria-label="Ajouter au planning" onClick={this.addToPlanning} size="small">
              <TodayIcon />
            </IconButton>
            <AddIngInListecourse ingsListe={this.state.ingsListe}/>
            <Recette id={this.state.idrecette} ingsListe={this.state.ingsListe} nom={this.state.nom} temps={this.state.temps} tempsGlobal={this.state.tempsGlobal} ingredients={this.state.ingredients} source={this.state.source} type={this.state.type} saison={this.state.saison} achats={this.state.achats} description={this.state.description} />
            <RecetteLinks id={this.state.idrecette}/>
            <br />
            <Comment page="/recettes/recette" comments={this.state.comments} recId={this.state.idrecette} oldpage={this.state.page} search={this.state.search} ings={this.state.ings} seachat={this.state.seachat} senom={this.state.senom} setype={this.state.setype} sesaison={this.state.sesaison} senote={this.state.senote} sesource={this.state.sesource} setps={this.state.setps} sepg={this.state.sepg}/>
          </Container>
        </React.Fragment>
      );
    }
  }
}
export default RecetteView;