import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ReCAPTCHA from "react-google-recaptcha";

const formUseStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const captchaRef = React.createRef();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: '',
      mdp: '',
      captcha: '',
      source: 'web',
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }
  onCaptchaChange(value) {
    console.log("Captcha value:", value);
  }
  onSubmit = (event) => {
    event.preventDefault();
    const recaptchaValue = captchaRef.current.getValue();
    this.setState({ captcha: recaptchaValue });
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/user/auth/'
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(this.state),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.status === 200) {
            return res.json()
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .then(function (data) {
          sessionStorage.setItem('token', data.token);
          sessionStorage.setItem('user', data.user);
          sessionStorage.setItem('role', data.role);
          console.log("auth token:" + data.token)
          // to refresh screen
          window.location.reload();
        })
        .catch(err => {
          console.error(err);
          alert('Error logging in please try again');
        });
    })
  }

  render() {
    if (sessionStorage.getItem('token')) {
      return null;
    } else {
      return (
        <ValidatorForm className={formUseStyles.root} noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <h1>Login:</h1>
          <TextValidator
            type="nom"
            name="nom"
            label="login"
            className={formUseStyles.textField}
            value={this.state.nom}
            onChange={this.handleInputChange}
            margin="normal"
            validators={['required']}
            errorMessages={['l\'identifiant est obligatoire']}
          />
          <TextValidator
            id="mdp"
            name="mdp"
            label="Password"
            className={formUseStyles.textField}
            type="password"
            value={this.state.mdp}
            onChange={this.handleInputChange}
            margin="normal"
            validators={['required', 'minStringLength:6']}
            errorMessages={['le mot de passe est obligatoire', '6 charactères minimum']}
          />
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_KAPTCHA_KEY}
            onChange={this.onCaptchaChange}
          />
          <Button type="submit" value="Submit" variant="contained" color="primary">Validate</Button>
        </ValidatorForm>
      );
    }

  }
}
export default Login;