import React from 'react';
import Chart from "react-google-charts";


class StatsSource extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoadingStatus: 'loading',
            chartData: []
        };
    }
    componentDidMount() {
        new Promise((resolve, reject) => {
            this.page = this.props.page
            let url = process.env.REACT_APP_SRV_URL
            url += '/stats/source'
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    const chartData = [['Source','Parent', 'Nombre de recette']]
                    chartData.push(['Source',null, 'Nombre de recette'])
                    for (let i = 0; i < result.rows.length; i += 1) {
                        chartData.push([result.rows[i].source,'Source', result.rows[i].nbr])
                    }
                    this.setState({
                        dataLoadingStatus: 'ready',
                        chartData: chartData,
                    })
                }).catch(err => console.log(err))
        });
    }

    render() {
        if (this.state.dataLoadingStatus === 'ready') {
            return (
                <Chart
                    width={500}
                    height={300}
                    chartType="TreeMap"
                    data={this.state.chartData}
                    options={{
                        chartArea: {
                            width: '50%',
                        },
                        title: 'Source avec plus de 10 recettes',
                        minColor: '#f00',
                        midColor: '#ddd',
                        maxColor: '#0d0',
                        headerHeight: 15,
                        fontColor: 'black',
                        showScale: true,
                        generateTooltip: (row, size, value) => {
                            return (
                              '<div style="background:#fd9; padding:10px; border-style:solid"> ' +
                              size +
                              '</div>'
                            )
                          },
                    }}
                    rootProps={{ 'data-testid': '4' }}
                />
            )
        } else {
            return (<div>Fetching data from API for recette by source</div>)
        }
    }
}
export default StatsSource;