import React from 'react';
import Chart from "react-google-charts";


class StatsNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoadingStatus: 'loading',
            chartData: []
        };
    }
    componentDidMount() {
        new Promise((resolve, reject) => {
            this.page = this.props.page
            let url = process.env.REACT_APP_SRV_URL
            url += '/stats/notes'
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    const chartData = [['Notes', 'Nombre de recette']]
                    for (let i = 0; i < result.rows.length; i += 1) {
                        chartData.push([result.rows[i].notes.toString(), result.rows[i].nbr])
                    }
                    this.setState({
                        dataLoadingStatus: 'ready',
                        chartData: chartData,
                    })
                }).catch(err => console.log(err))
        });
    }

    render() {
        if (this.state.dataLoadingStatus === 'ready') {
            return (
                <Chart
                    width={500}
                    height={300}
                    chartType="PieChart"
                    data={this.state.chartData}
                    options={{
                        chartArea: {
                            width: '100%',
                        },
                        title: 'Recettes Par Notes',
                        is3D: true,
                    }}
                    rootProps={{ 'data-testid': '2' }}
                />
            )
        } else {
            return (<div>Fetching data from API for recette by notes</div>)
        }
    }
}
export default StatsNotes;