import React from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import queryString from 'query-string';
import {
  MuiPickersUtilsProvider, KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import format from "date-fns/format";
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { escape } from 'html-escaper';
import Rating from '@material-ui/lab/Rating';

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "yyyy", { locale: this.locale });
  }
}

class CaveTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    if (this.props.location.search) {
      const values = queryString.parse(this.props.location.search);
      this.state = {
        vins: {},
        emplacements: ["Rueil","Rome"],
        senom: values.senom,
        senote: values.senote,
        seappelation: values.seappelation,
        secategorie: values.secategorie,
        seemplacement: values.seemplacement,
      };
    } else {
      this.state = {
        vins: {},
        emplacements: ["Rueil","Rome"],
        senom: '',
        senote: '',
        seappelation: '',
        secategorie: '',
        seemplacement: '',
      };
    }
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/cave'
      fetch(url)
        .then(response => response.json())
        .then(result => {
          this.setState({ vins: result });
        }).catch(err => console.log(err))
    });
  }

  render() {
    return (
      <MaterialTable
        icons={tableIcons}
        title="Cave"
        columns={[
          { title: 'ID', field: 'idcave', filtering: false, hidden: true },
          { title: 'Nom', field: 'nom', filtering: true, sorting: true },
          { title: 'Quantité', field: 'nombre', filtering: true, sorting: true },
          {
            title: 'Année', field: 'annee', filtering: false, sorting: false,
            render: rowData => {
              if (rowData.annee) {
                var exp = moment(rowData.annee, 'YYYY');
                var now = moment(new Date(), 'YYYY');
                return <TextField disabled value={rowData.annee} />
              }
            },
            editComponent: props => (
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}> <KeyboardDatePicker
                margin="normal"
                views={['year']}
                format="yyyy"
                onChange={e => props.onChange(e)}
                value={moment(props.value, 'YYYY')}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
              /></MuiPickersUtilsProvider>
            )
          },
          { title: 'Appelation', field: 'appelation', filtering: true, sorting: true },
          { title: 'Categorie', field: 'categorie', filtering: true, sorting: true },
          {
            title: 'Note', field: 'note', type: 'numeric', filtering: true, sorting: false, defaultFilter: this.state.senote,
            render: rowData => {
              return <Rating name="note" value={rowData.note} readOnly max={3} size="small" />
            }, editComponent: props => (
              <Rating
                name="note"
                value={props.value}
                size="small"
                max={3}
                onChange={(event, newValue) => {
                  props.onChange(newValue);
                }}
              />
            ),
          },
          {
            title: 'Emplacement', field: 'emplacement', filtering: true, sorting: true,
            editComponent: propsEmp => (
              <Autocomplete
                options={this.state.emplacements}
                getOptionLabel={option => option}
                style={{ width: 300 }}
                onChange={(event, value) => {
                  if (value != null) {
                    propsEmp.onChange(value);
                  }
                }}
                renderInput={params => <TextField {...params} label={propsEmp.value} variant="outlined" />}
              />
            )
          },
          { title: 'Description', field: 'description', filtering: false, sorting: false },
          { title: 'Utilisateur', field: 'utilisateur', filtering: false, sorting: false, hidden: true },
        ]}
        data={query =>
          new Promise((resolve, reject) => {
            let senomNoFilter = true
            let senoteNoFilter = true
            let appelationeNoFilter = true
            let categorieNoFilter = true
            let emplacementNoFilter = true
            let url = process.env.REACT_APP_SRV_URL
            url += '/cave?'
            url += 'rp=' + query.pageSize
            url += '&page=' + (query.page + 1)
            if (query.orderDirection) {
              url += '&sortorder=' + query.orderDirection
            }
            query.filters.forEach(element => {
              if (element.column.title === 'Nom') {
                url += '&nom='
                url += escape(encodeURIComponent(element.value))
                this.state.senom = element.value
                senomNoFilter = false
              } else if (element.column.title === 'Note') {
                url += '&note='
                url += element.value
                this.state.senote = element.value
                senoteNoFilter = false
              } else if (element.column.title === 'Quantité') {
                url += '&nombre='
                url += element.value
                this.state.senote = element.value
                senoteNoFilter = false
              } else if (element.column.title === 'Appelation') {
                url += '&appelation='
                url += element.value
                this.state.seappelation = element.value
                appelationeNoFilter = false
              } else if (element.column.title === 'Categorie') {
                url += '&categorie='
                url += element.value
                this.state.secategorie = element.value
                categorieNoFilter = false
              } else if (element.column.title === 'Emplacement') {
                url += '&emplacement='
                url += element.value
                this.state.seemplacement = element.value
                emplacementNoFilter = false
              }
            })
            fetch(url)
              .then(response => response.json())
              .then(result => {
                resolve({
                  data: result.rows,
                  page: result.page - 1,
                  totalCount: result.count,
                })
              })
          })
        }
        editable={{
          isDeletable: rowData => (rowData.utilisateur === sessionStorage.getItem('user')),
          isEditable: rowData => (rowData.utilisateur === sessionStorage.getItem('user') ),
          onRowAdd: query =>
            new Promise((resolve, reject) => {
              if (query.nom === undefined || query.categorie === undefined) {
                reject();
              } else {
                let url = process.env.REACT_APP_SRV_URL
                url += '/cave/'
                fetch(url, {
                  method: 'POST',
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + sessionStorage.getItem('token'),
                  },
                  body: '{"utilisateur":"' + sessionStorage.getItem('user') + '","nom":"' + escape(encodeURIComponent(query.nom)) + '","nombre":"' + query.nombre + '","note":"' + query.note + '","annee":"' + moment(query.annee, 'YYYY').format("YYYY") + '","appelation":"' + escape(encodeURIComponent(query.appelation)) + '","categorie":"' + escape(encodeURIComponent(query.categorie)) + '","emplacement":"' + escape(encodeURIComponent(query.emplacement)) + '","description":"' + escape(encodeURIComponent(query.description)) + '"}'
                })
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                    })
                  })
              }
            }),
          onRowUpdate: query =>
            new Promise((resolve, reject) => {
              if (query.nom === undefined || query.categorie === undefined) {
                reject();
              } else {
                let url = process.env.REACT_APP_SRV_URL
                url += '/cave/'
                url += query.idcave
                url += '?user=' + sessionStorage.getItem('user')
                fetch(url, {
                  method: 'PUT',
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + sessionStorage.getItem('token'),
                  },
                  body: '{"utilisateur":"' + sessionStorage.getItem('user') + '","nom":"' + escape(encodeURIComponent(query.nom)) + '","nombre":"' + query.nombre + '","note":"' + query.note + '","annee":"' + moment(query.annee, 'YYYY').format("YYYY") + '","appelation":"' + escape(encodeURIComponent(query.appelation)) + '","categorie":"' + escape(encodeURIComponent(query.categorie)) + '","emplacement":"' + escape(encodeURIComponent(query.emplacement)) + '","description":"' + escape(encodeURIComponent(query.description)) + '"}'
                })
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                    })
                  })
              }
            }),
          onRowDelete: query =>
            new Promise((resolve, reject) => {
              let url = process.env.REACT_APP_SRV_URL
              url += '/cave/'
              url += query.idcave
              url += '?user=' + sessionStorage.getItem('user')
              fetch(url, { method: 'DELETE', headers: { "Authorization": "Bearer " + sessionStorage.getItem('token') } })
                .then(response => response.json())
                .then(result => {
                  resolve({
                  })
                })
            })
        }}
        options={{
          search: false,
          filtering: true,
          sorting: true,
          rowStyle: x => {
            if (x.tableData.id % 2) {
              return { fontSize: 16, backgroundColor: "#f2f2f2" }
            } else {
              return { fontSize: 16 }
            }
          }
        }}
      />
    )
  }
}
export default CaveTable;