import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


class CommentDelete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openDialogCommentConfirm: false
    };
    this.removeComment = this.removeComment.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleOpenDialog() {
    this.setState({
      openDialogCommentConfirm: true
    });
  }

  handleCloseDialog() {
    this.setState({
      openDialogCommentConfirm: false
    });
  }

  removeComment() {
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/recettes/comment'
      url += '/' + this.props.idcommentRecettes
      url += '?user=' + sessionStorage.getItem('user')
      fetch(url, { method: 'DELETE' , headers: { "Authorization": "Bearer "+sessionStorage.getItem('token') }})
        .then(response => response.json())
        .then(result => {
          this.handleCloseDialog();
          let url = this.props.page + "?idrecette=" + this.props.recId +"&page=" + this.props.oldpage
          if (this.props.search) {
            url += "&search="+this.props.search
          }
          if (this.props.ings) {
            url += "&ings="+this.props.ings
          }
          if (this.props.seachat) {
            url += "&seachat="+this.props.seachat
          }
          if (this.props.senom) {
            url += "&senom="+this.props.senom
          }
          if (this.props.setype) {
            url += "&setype="+this.props.setype
          }
          if (this.props.senote) {
            url += "&senote="+this.props.senote
          }
          if (this.props.sesource) {
            url += "&sesource="+this.props.sesource
          }
          if (this.props.setps) {
            url += "&setps="+this.props.setps
          }
          if (this.props.sepg) {
            url += "&setps="+this.props.sepg
          }
          window.location.href =  url;
        }).catch(err => console.log(err))
    });
  }
  
  render() {
    if (this.props.user === sessionStorage.getItem('user')) {
      return (<React.Fragment>
          <IconButton id={"close" + this.props.idcommentRecettes} color="inherit" aria-label="Supprimer" size="small" onClick={this.handleOpenDialog}  >
              <CloseIcon />
          </IconButton>
          <Dialog id={"dialog" + this.props.idcommentRecettes}
          open={this.state.openDialogCommentConfirm}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
          <DialogTitle id={"alert-dialog-title" + this.props.idcommentRecettes}>{"Confirm delete?"}</DialogTitle>
          <DialogContent id={"alert-dialog-context" + this.props.idcommentRecettes}>
            <DialogContentText id="alert-dialog-description">
              Voulez-vous supprimer le commentaire ?
            </DialogContentText>
          </DialogContent>
          <DialogActions id={"alert-dialog-action" + this.props.idcommentRecettes}>
            <Button id={"alert-dialog-annuler" + this.props.idcommentRecettes} onClick={this.handleCloseDialog} color="primary">
              Annuler
            </Button>
            <Button d={"alert-dialog-validerr" + this.props.idcommentRecettes} onClick={this.removeComment} color="primary" autoFocus>
              Valider
            </Button>
          </DialogActions>
          </div>
        </Dialog>
    </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
export default CommentDelete;