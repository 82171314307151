import React from 'react';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CommentEdit from './CommentEdit';
import CommentDelete from './CommentDelete';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));


class Comment extends React.Component {

  render() {
    return (<React.Fragment>
      <Container className={useStyles.container}>
        {Object.values(this.props.comments).map(comment =>
          <div id={"div" + comment.idcommentRecettes} >
            <Paper id={comment.idcommentRecettes} key={comment.idcommentRecettes} elevation={3}>
              <Typography id={"comment" + comment.idcommentRecettes} variant="h6" component="h4">
                {comment.commentaire}
                <Rating id={"rating" + comment.idcommentRecettes} name="note" value={comment.note} readOnly max={3} size="small" />
              </Typography>
              <Typography id={"commentdate" + comment.idcommentRecettes} component="p" >
                  {comment.user}:
                    <Moment id={"moment" + comment.idcommentRecettes} local format="DD/MM/YYYY" >
                  {comment.dateComment}
                </Moment><br />
              </Typography>
              <CommentDelete id={"del" + comment.idcommentRecettes} user={comment.user} idcommentRecettes={comment.idcommentRecettes}  page={this.props.page} recId={this.props.recId} oldpage={this.props.oldpage} search={this.props.search} ings={this.props.ings} seachat={this.props.seachat} senom={this.props.senom} setype={this.props.setype} senote={this.props.senote} sesource={this.props.sesource} setps={this.props.setps} sepg={this.props.sepg}/>
            </Paper>
            <br />
          </div>
        )}
      <CommentEdit page={this.props.page} recId={this.props.recId} oldpage={this.props.oldpage} search={this.props.search} ings={this.props.ings} seachat={this.props.seachat} senom={this.props.senom} setype={this.props.setype} senote={this.props.senote} sesource={this.props.sesource} setps={this.props.setps} sepg={this.props.sepg}/>
      </Container>
    </React.Fragment>
    );
  }
}
export default Comment;