import React from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { escape, unescape } from 'html-escaper';

const tableIcons = {
    Add: AddBox,
    Check: Check,
    Clear: Clear,
    Delete: DeleteOutline,
    DetailPanel: ChevronRight,
    Edit: Edit,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search: Search,
    SortArrow: ArrowUpward,
    ThirdStateCheck: Remove,
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 'auto',
        padding: '10px 12px',
    },
}));

const types = [
    {
        value: 'Plat',
        label: 'Plat',
    },
    {
        value: 'Dessert',
        label: 'Dessert',
    },
    {
        value: 'Viande',
        label: 'Viande',
    },
    {
        value: 'Poisson',
        label: 'Poisson',
    },
    {
        value: 'Primi',
        label: 'Primi',
    },
    {
        value: 'Entrée',
        label: 'Entrée',
    },
    {
        value: 'Autres',
        label: 'Autres',
    },
]

const saisons = [
    {
        value: 'Toutes',
        label: 'Toutes',
    },
    {
        value: 'Printemps',
        label: 'Printemps',
    },
    {
        value: 'Eté',
        label: 'Eté',
    },
    {
        value: 'Automne',
        label: 'Automne',
    },
    {
        value: 'Hiver',
        label: 'Hiver',
    }
]

class RecetteEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idrecette: '',
            page: '',
            search: '',
            seings: '',
            seachat: '',
            senom: '',
            setype: '',
            sesaison: '',
            senote: '',
            sesource: '',
            setps: '',
            sepg: 1,
            status: '',
            ings: {},
            ingsListe: [],
            achats: false,
            type: '',
            saison: '',
            temps: '',
            source: '',
            description: '',
            nom: ''
        }
        new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_SRV_URL
            url += '/ingredients?'
            url += 'label=ALL'
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    this.setState({ ings: result });
                }).catch(err => console.log(err))
        });
        this.handleClose = this.handleClose.bind(this);
        this.buildStringIngList = this.buildStringIngList.bind(this);
        this.buildAchat = this.buildAchat.bind(this);
        this.calcTempsGlobal = this.calcTempsGlobal.bind(this);
    }

    handleCheckboxChange = (event) => {
        this.setState({ achats: event.target.checked })
    };
    handleTypeSelectChange = (event) => {
        this.setState({ type: event.target.value })
    };
    handleSaisonSelectChange = (event) => {
        this.setState({ saison: event.target.value })
    };
    handleChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
    }

    componentDidMount() {
        if (this.props.ingsListe) {
            this.setState({ ingsListe: this.props.ingsListe });
        }
    }

    buildStringIngList() {
        let stringIngList = ""
        let isFirst = true;
        if (this.state.ingsListe) {
            this.state.ingsListe.forEach(function (item) {
                if (!isFirst) {
                    stringIngList += " + ";
                }
                if (item.quantite) {
                    stringIngList += "(" + item.quantite + ") " + item.ingredient
                } else {
                    stringIngList += "() " + item.ingredient
                }

                isFirst = false;
            });
        }
        return stringIngList;
    }

    componentDidUpdate(prevProps) {
        if ((this.props.nom) && (this.props.nom !== prevProps.nom)) {
            this.setState({ nom: this.props.nom })
        }
        if ((this.props.source) && (this.props.source !== prevProps.source)) {
            this.setState({ source: this.props.source })
        }
        if ((this.props.achats) && (this.props.achats !== prevProps.achats)) {
            this.setState({ achats: Boolean(this.props.achats) })
        }
        if ((this.props.type) && (this.props.type !== prevProps.type)) {
            this.setState({ type: this.props.type })
        }
        if ((this.props.saison) && (this.props.saison !== prevProps.saison)) {
            this.setState({ saison: this.props.saison })
        }
        if ((this.props.temps) && (this.props.temps !== prevProps.temps)) {
            this.setState({ temps: this.props.temps })
        }
        if ((this.props.description) && (this.props.description !== prevProps.description)) {
            this.setState({ description: this.props.description })
        }
        if ((this.props.ingsListe) && (this.props.ingsListe !== prevProps.ingsListe)) {
            this.setState({ ingsListe: this.props.ingsListe })
        }
    }

    handleClose() {
        let url = "/recettes";
        let isFirst = true;
        if (this.props.search) {
            url += "?search=" + this.props.search
            isFirst = false
        }
        if (this.props.seings) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "ings=" + this.props.seings
            isFirst = false
        }
        if (this.props.seachat) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "seachat=" + this.props.seachat
            isFirst = false
        }
        if (this.props.senom) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "senom=" + this.props.senom
            isFirst = false
        }
        if (this.props.setype) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "setype=" + this.props.setype
            isFirst = false
        }
        if (this.props.sesaison) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "sesaison=" + this.props.sesaison
            isFirst = false
        }
        if (this.props.senote) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "senote=" + this.props.senote
            isFirst = false
        }
        if (this.props.sesource) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "sesource=" + this.props.sesource
            isFirst = false
        }
        if (this.props.setps) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "setps=" + this.props.setps
            isFirst = false
        }
        if (this.props.sepg) {
            let start = "&"
            if (isFirst) {
                start = "?"
            }
            url += start + "sepg=" + this.props.sepg
        }
        window.location.href = url
    }

    handleSubmit = (event) => {
        if (this.state.ingsListe) {
            let jsonparams = '{"utilisateur":"' + sessionStorage.getItem('user')
            jsonparams += '","nom":"' + escape(encodeURIComponent(this.state.nom)) + '"'
            if (this.state.temps) {
                jsonparams += ',"temps":"' + this.state.temps + '"'
                jsonparams += ',"tempsglobal":' + this.calcTempsGlobal(this.state.temps) + ''
            } else {
                jsonparams += ',"temps":""'
                jsonparams += ',"tempsglobal":0'
            }
            if (this.state.ingsListe) {
                jsonparams += ',"ingredients":"' + escape(encodeURIComponent(this.buildStringIngList())) + '"'
            } else {
                jsonparams += ',"ingredients":""'
            }
            if (this.state.description) {
                jsonparams += ',"description":"' + escape(encodeURIComponent(this.state.description)) + '"'
            } else {
                jsonparams += ',"description":""'
            }
            if (this.state.source) {
                jsonparams += ',"source":"' + escape(encodeURIComponent(this.state.source)) + '"'
            } else {
                jsonparams += ',"source":""'
            }
            if (this.state.type) {
                jsonparams += ',"type":"' + this.state.type + '"'
            } else {
                jsonparams += ',"type":"Plat"'
            }
            if (this.state.saison) {
                jsonparams += ',"saison":"' + this.state.saison + '"'
            } else {
                jsonparams += ',"saison":"Toutes"'
            }
            if (this.state.achats) {
                jsonparams += ',"achats":' + this.buildAchat() + ''
            } else {
                jsonparams += ',"achats":0'
            }
            jsonparams += '}'
            console.log(jsonparams)
            if (this.props.status === "edit") {
                new Promise((resolve, reject) => {
                    let url = process.env.REACT_APP_SRV_URL
                    url += '/recettes'
                    url += '/' + this.props.id
                    fetch(url, {
                        method: 'PUT',
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Authorization": "Bearer " + sessionStorage.getItem('token')
                        },
                        body: jsonparams
                    })
                        .then(response => response.json())
                        .then(result => {
                            this.handleClose();
                        }).catch(err => console.log(err))
                });
            } else if (this.props.status === "add") {
                new Promise((resolve, reject) => {
                    let url = process.env.REACT_APP_SRV_URL
                    url += '/recettes'
                    fetch(url, {
                        method: 'POST',
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Authorization": "Bearer " + sessionStorage.getItem('token')
                        },
                        body: jsonparams
                    })
                        .then(response => response.json())
                        .then(result => {
                            this.handleClose();
                        }).catch(err => console.log(err))
                });
            }
        }
    }

    buildAchat() {
        if (this.state.achats) {
            return "1";
        } else {
            return "0";
        }
    }

    calcTempsGlobal(temps) {
        var tpsgl = 0
        var tpslist = temps.split("/");
        tpslist.forEach(function (item) {
            tpsgl += Number(item.trim())
        })
        return tpsgl;
    }

    render() {
        return (<ValidatorForm className={useStyles.root} ref="form" autoComplete="off" onSubmit={this.handleSubmit} onError={errors => console.log(errors)}>
            <React.Fragment>
                <TextField id="idrecette" name="idrecette" value={this.props.id} style={{ display: 'none' }} />
                <TextField id="ingredients" name="ingredients" value={this.buildStringIngList()} style={{ display: 'none' }} />
                <TextField id="achats" name="achats" value={this.state.achats} style={{ display: 'none' }} />
                <div id={"divrec" + this.props.id}> <TextValidator
                    id={"name" + this.props.id}
                    label="Nom"
                    validators={['required', 'maxStringLength:100']}
                    errorMessages={['le nom est obligatoire', '100 charactères maximum']}
                    name="nom"
                    value={this.state.nom}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                /></div>
                <div><FormControl><TextValidator
                    id={"temps" + this.props.id}
                    label="Temps"
                    name="temps"
                    value={this.state.temps}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    validators={['matchRegexp:^[0-9]*$|^[0-9]+/[0-9]+$|^[0-9]+/[0-9]+/[0-9]+$']}
                    errorMessages={['format temps en mn: [0-9]/[0-9]/[0-9]']}
                    aria-describedby="temps-helper-text"
                />
                    <FormHelperText id="temps-helper-text">format temps en mn: [0-9]/[0-9]/[0-9] </FormHelperText>
                </FormControl>
                </div>
                <br />
                <MaterialTable
                    icons={tableIcons}
                    title="Ingredients:"
                    columns={[
                        {
                            title: 'Ingredient', field: 'ingredient', sorting: false, editable: 'onAdd',
                            editComponent: propsIng => (
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    options={this.state.ings}
                                    getOptionLabel={option => option.label_fr}
                                    style={{ width: 300 }}
                                    includeInputInList
                                    onChange={(event, value) => {
                                        if (value != null) {
                                            propsIng.onChange(value.label_fr);
                                        }
                                    }}
                                    onInputChange={(event, value) => {
                                        if (value != null) {
                                            this.state.ings.push({ "label_fr": value, "label_en": "", "label_it": "" })
                                            propsIng.onChange(value);
                                        }
                                    }}
                                    renderInput={params => <TextField {...params} label={propsIng.value} variant="outlined" />}
                                />
                            ),
                            render: rowData => {
                                return unescape(rowData.ingredient)
                            }
                        },
                        {
                            title: 'Quantité', field: 'quantite', sorting: false,
                            render: rowData => {
                                if (rowData.quantite) {
                                    return unescape(rowData.quantite)
                                } else {
                                    return rowData.quantite
                                }
                            },
                            editComponent: propsQtt => {
                                if (propsQtt.value) {
                                    return <TextField label={unescape(propsQtt.value)} onChange={e => propsQtt.onChange(e.target.value)} variant="outlined" />
                                } else {
                                    return <TextField label={(propsQtt.value)} onChange={e => propsQtt.onChange(e.target.value)} variant="outlined" />
                                }
                            }
                        }
                    ]}
                    data={this.state.ingsListe}
                    editable={{
                        onRowAdd: query =>
                            new Promise((resolve, reject) => {
                                let igl = this.state.ingsListe
                                igl.push(query);
                                this.setState({ ingsListe: igl });
                                resolve();
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                let igl = this.state.ingsListe
                                const index = igl.indexOf(oldData);
                                igl[index] = newData;
                                this.setState({ ingsListe: igl });
                                resolve();
                            }),
                        onRowDelete: query =>
                            new Promise((resolve, reject) => {
                                let igl = this.state.ingsListe
                                const index = igl.indexOf(query);
                                igl.splice(index, 1);
                                this.setState({ ingsListe: igl });
                                resolve();
                            })
                    }}
                    options={{
                        search: false,
                        filtering: false,
                        sorting: false,
                        paging: false,
                        showTitle: true,
                        toolbar: true,
                        rowStyle: x => {
                            if (x.tableData.id % 2) {
                                return { fontSize: 16, backgroundColor: "#f2f2f2" }
                            } else {
                                return { fontSize: 16 }
                            }
                        },
                        headerStyle: {
                            backgroundColor: '#115293',
                            color: '#FFF',
                        }
                    }}
                />
                <div><TextValidator
                    id={"source" + this.props.id}
                    label="source"
                    name="source"
                    value={this.state.source}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    validators={['maxStringLength:45']}
                    errorMessages={['45 chars maximum']}
                />
                    <table><tbody><tr>
                        <td><TextField
                            id={"type" + this.props.id}
                            label="Type"
                            name="type"
                            select
                            value={this.state.type}
                            multiple={false}
                            margin="normal"
                            onChange={this.handleTypeSelectChange}
                            variant="outlined"
                            SelectProps={{ native: true, }} >
                            {types.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField></td>
                        <td><TextField
                            id={"saison" + this.props.id}
                            label="Saison"
                            name="saison"
                            select
                            value={this.state.saison}
                            multiple={false}
                            margin="normal"
                            onChange={this.handleSaisonSelectChange}
                            variant="outlined"
                            SelectProps={{ native: true, }} >
                            {saisons.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField></td>
                    </tr></tbody></table>
                </div>
                <div><FormControlLabel
                    control={
                        <Checkbox
                            value="true"
                            color="primary"
                            onChange={this.handleCheckboxChange}
                            checked={this.state.achats}
                        />
                    }
                    label="Achats"
                /></div>
                <div><TextValidator
                    id={"description" + this.props.id}
                    label="Description"
                    name="description"
                    multiline
                    rowsMax="10"
                    value={this.state.description}
                    margin="normal"
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    validators={['maxStringLength:2048']}
                    errorMessages={['2048 charactères maximum']}
                /></div>
                <div className={useStyles.container}>
                    <table><tbody><tr>
                        <td><Button onClick={this.handleClose} className={useStyles.button} color="primary" variant="contained" startIcon={<DeleteIcon />}>
                            Annuler
                </Button></td>
                        <td><Button type="submit" className={useStyles.button} color="primary" variant="contained" startIcon={<SaveIcon />}>
                            Valider
                </Button></td>
                    </tr></tbody></table>
                </div>
            </React.Fragment>
        </ValidatorForm>
        );
    }
}
export default RecetteEdit;