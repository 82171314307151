import React from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Today from '@material-ui/icons/Today';
import queryString from 'query-string';
import Rating from '@material-ui/lab/Rating';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  button: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn,
  Today: Today
};

//const {openDialog, handleOpenDialog} = useModal();

class RecettesTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    if (this.props.location.search) {
      const values = queryString.parse(this.props.location.search);
      this.state = {
        search: values.search,
        ings: values.ings,
        seachat: values.seachat,
        senom: values.senom,
        setype: values.setype,
        sesaison: values.sesaison,
        senote: values.senote,
        sesource: values.sesource,
        setps: values.setps,
        sepg: values.sepg,
        isNotSe: false
      };
      this.props.history.push({
        search: ''
      });
    } else {
      this.state = {
        search: '',
        ings: '',
        seachat: '',
        senom: '',
        setype: '',
        sesaison:'',
        senote: '',
        sesource:'',
        sepg: '0',
        isNotSe: true
      };
    }
    this.addRec = this.addRec.bind(this);
  }

  addToPlanning(idrec) {
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/planning?'
      url += 'user=' + sessionStorage.getItem('user')
      url += '&position=stock'
      url += '&id=' + idrec
      fetch(url, { method: 'PUT',headers: { "Authorization": "Bearer "+sessionStorage.getItem('token') } })
        .then(response => response.json())
        .then(result => {
        }).catch(err => console.log(err))
    });
  }

  addRec() {
    let url = "/recettes/recette?page=recettes&status=add"
    if (this.state.search) {
      url += '&search=' + this.state.search
    }
    if (this.state.ings) {
      url += '&ings=' + this.state.ings
    }
    if (this.state.seachat) {
      url += '&seachat=' + this.state.seachat
    }
    if (this.state.senom) {
      url += '&senom=' + this.state.senom
    }
    if (this.state.setype) {
      url += '&setype=' + this.state.setype
    }
    if (this.state.sesaison) {
      url += '&sesaison=' + this.state.sesaison
    }
    if (this.state.senote) {
      url += '&senote=' + this.state.senote
    }
    if (this.state.sesource) {
      url += '&sesource=' + this.state.sesource
    }
    if (this.state.setps) {
      url += '&setps=' + this.state.setps
    }
    if (this.state.sepg) {
      url += '&sepg=' + this.state.sepg
    }
    window.location.href = url
  }

render() {
  return (
    <Box className={useStyles.container}>
      <IconButton id="add " color="primary" aria-label="Ajout recette" className={useStyles.button}  onClick={this.addRec} >
        <AddIcon />
      </IconButton>
      <MaterialTable
        icons={tableIcons}
        title="Recettes"
        columns={[
          { title: 'ID', field: 'idrecette', filtering: false, sorting: false, hidden: true },
          { title: 'Nom', field: 'nom', defaultFilter: this.state.senom },
          { title: 'Temps', field: 'temps', filtering: false, sorting: false },
          { title: 'Durée', field: 'tempsGlobal', type: 'numeric', filtering: true, sorting: false, defaultFilter: this.state.setps },
          { title: 'Ingredients', field: 'ingredients', headerStyle: {minWidth: 500}, cellStyle: {minWidth: 500}, sorting: false, defaultFilter: this.state.ings },
          { title: 'Source', field: 'source', filtering: true, sorting: false, defaultFilter: this.state.sesource },
          { title: 'Type', field: 'type', sorting: false, defaultFilter: this.state.setype },
          { title: 'Saison', field: 'saison', sorting: false, defaultFilter: this.state.sesaison },
          {
            title: 'Achats', field: 'achats', type: 'boolean', sorting: false, defaultFilter: this.state.seachat,
            render: rowData => {
              if (rowData.achats === 1) {
                return <input type="checkbox" disabled="disabled" defaultChecked />
              }
            }
          },
          { title: 'Utilisateur', field: 'utilisateur_user', filtering: false, sorting: false },
          {
            title: 'Note', field: 'note', type: 'numeric', filtering: true, sorting: false, defaultFilter: this.state.senote,
            render: rowData => {
              return <Rating name="notes" value={rowData.notes} readOnly max={3} size="small" />
            }
          },
        ]}
        data={query =>
          new Promise((resolve, reject) => {
            let ingsNoFilter = true
            let seachatNoFilter = true
            let senomNoFilter = true
            let setypeNoFilter = true
            let sesaisonNoFilter = true
            let senoteNoFilter = true
            let sesourceNoFilter = true
            let setpsNoFilter = true
            let url = process.env.REACT_APP_SRV_URL
            url += '/recettes?'
            url += 'orig=web'
            url += '&rp=' + query.pageSize
            if (this.state.isNotSe) {
              this.state.sepg = query.page
            } else {
              this.state.isNotSe = true
              if (!this.state.sepg) {
                this.state.sepg = '0'
              }
            }
            let calpg = Number(this.state.sepg) + Number(1)
            url += '&page=' +  calpg
            if (query.orderDirection) {
              url += '&sortname=nom'
              url += '&sortorder=' + query.orderDirection
            }
            if (query.search) {
              url += '&search=' + escape(encodeURIComponent(query.search)) 
            }
            this.state.search = query.search;
            query.filters.forEach(element => {
              if (element.column.title === 'Achats') {
                if (element.value === 'checked') {
                  url += '&achat=1'
                } else {
                  url += '&achat=0'
                }
                seachatNoFilter = false
                this.state.seachat = element.value
              } else if (element.column.title === 'Nom') {
                url += '&nom='
                url += escape(encodeURIComponent(element.value))
                this.state.senom = element.value
                senomNoFilter = false
              } else if (element.column.title === 'Ingredients') {
                url += '&ings='
                url += escape(encodeURIComponent(element.value))
                this.state.ings = element.value
                ingsNoFilter = false
              } else if (element.column.title === 'Type') {
                url += '&type='
                url += element.value
                this.state.setype = element.value
                setypeNoFilter = false
              } else if (element.column.title === 'Saison') {
                url += '&saison='
                url += element.value
                this.state.sesaison = element.value
                sesaisonNoFilter = false
              } else if (element.column.title === 'Note') {
                url += '&note='
                url += element.value
                this.state.senote = element.value
                senoteNoFilter = false
              } else if (element.column.title === 'Source') {
                url += '&source='
                url += element.value
                this.state.sesource = element.value
                sesourceNoFilter = false
              } else if (element.column.title === 'Durée') {
                url += '&tps='
                url += escape(encodeURIComponent(element.value))
                this.state.setps = element.value
                setpsNoFilter = false
              }
            })
            fetch(url)
              .then(
                response => response.json()
              )
              .then(result => {
                resolve({
                  data: result.rows,
                  page: result.page - 1,
                  totalCount: result.count,
                })
                if (this.props.location) {
                  this.props.location.search = '';
                }
                if (ingsNoFilter) {
                  this.state.ings = ''
                }
                if (seachatNoFilter) {
                  this.state.seachat = ''
                }
                if (senomNoFilter) {
                  this.state.senom = ''
                }
                if (setypeNoFilter) {
                  this.state.setype = ''
                }
                if (sesaisonNoFilter) {
                  this.state.sesaison = ''
                }
                if (senoteNoFilter) {
                  this.state.senote = ''
                }
                if (sesourceNoFilter) {
                  this.state.sesource = ''
                }
                if (setpsNoFilter) {
                  this.state.setps = ''
                }
              })
          })
        }
        editable={{
          isDeletable: rowData => rowData.utilisateur_user === sessionStorage.getItem('user'),
          onRowDelete: query =>
            new Promise((resolve, reject) => {
              let url = process.env.REACT_APP_SRV_URL
              url += '/recettes/'
              url += query.idrecette
              url += '?user=' + query.utilisateur_user
              fetch(url, { method: 'DELETE', headers: { "Authorization": "Bearer "+sessionStorage.getItem('token') }  })
                .then(response => response.json())
                .then(result => {
                  resolve({
                  })
                })
            }),
        }}
        options={{
          search: true,
          filtering: true,
          searchText: this.state.search,
          sorting: true,
          pageSize: 20,
          rowStyle:  x => {
            if (x.tableData.id % 2) {
                return {fontSize: 14,backgroundColor: "#f2f2f2"}
            } else {
                return {fontSize: 14}
            }
          }
        }}
        actions={[
          {
            icon: Today,
            tooltip: 'Add to planing',
            onClick: (event, rowData) => { this.addToPlanning(rowData.idrecette) }
          },
          {
            icon: Search,
            tooltip: 'Voir Recette',
            onClick: (event, rowData) => {
              let url = "/recettes/recette?page=recettes&idrecette=" + rowData.idrecette
              if (this.state.search) {
                url += '&search=' + this.state.search
              }
              if (this.state.ings) {
                url += '&ings=' + this.state.ings
              }
              if (this.state.seachat) {
                url += '&seachat=' + this.state.seachat
              }
              if (this.state.senom) {
                url += '&senom=' + this.state.senom
              }
              if (this.state.setype) {
                url += '&setype=' + this.state.setype
              }
              if (this.state.sesaison) {
                url += '&sesaison=' + this.state.sesaison
              }
              if (this.state.senote) {
                url += '&senote=' + this.state.senote
              }
              if (this.state.sesource) {
                url += '&sesource=' + this.state.sesource
              }
              if (this.state.setps) {
                url += '&setps=' + this.state.setps
              }
              if (this.state.sepg) {
                url += '&sepg=' + this.state.sepg
              }
              window.location.href = url
            }
          },
          rowData => ({
            icon: Edit,
            tooltip: 'Editer Recette',
            disabled: rowData.utilisateur_user !== sessionStorage.getItem('user'),
            onClick: (event, rowData) => {
              let url = "/recettes/recette?page=recettes&status=edit&idrecette=" + rowData.idrecette
              if (this.state.search) {
                url += '&search=' + this.state.search
              }
              if (this.state.ings) {
                url += '&ings=' + this.state.ings
              }
              if (this.state.seachat) {
                url += '&seachat=' + this.state.seachat
              }
              if (this.state.senom) {
                url += '&senom=' + this.state.senom
              }
              if (this.state.setype) {
                url += '&setype=' + this.state.setype
              }
              if (this.state.sesaison) {
                url += '&sesaison=' + this.state.sesaison
              }
              if (this.state.senote) {
                url += '&senote=' + this.state.senote
              }
              if (this.state.sesource) {
                url += '&sesource=' + this.state.sesource
              }
              if (this.state.setps) {
                url += '&setps=' + this.state.setps
              }
              if (this.state.sepg) {
                url += '&sepg=' + this.state.sepg
              }
              window.location.href = url
            },
          })
        ]}
      />
    </Box>
  )
}
}

export default RecettesTable;