import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logotomato from '../images/64px-Tomato.svg.png'
import logoflask from '../images/48px-Conical_flask_teal.svg.png'
import Button from '@material-ui/core/Button';
const { version } = require('../../package.json');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
  },
}));

function ToolBarRec() {
  const classes = useStyles();
 
  const [buttonName, setButtonName] = useState(sessionStorage.getItem('user'));

  function logout() {
    sessionStorage.removeItem('token'); 
    sessionStorage.removeItem('user');
    setButtonName('')
    window.location.reload();
  }

  return (
        <Toolbar>
            <Typography edge="start" color="inherit" className={classes.title} aria-label="menu">
                <img src={logotomato} alt="Logo" height="42" width="42"	align="middle" /> Recettes Manager
                <img src={logoflask}  alt="Logo" height="42"	width="42" align="middle" />
            </Typography>
            <Button color="inherit" onClick={logout}>{buttonName}</Button>
            <h6>[version: {version}]</h6>
        </Toolbar>
  );
}

export default ToolBarRec;