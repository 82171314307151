import React from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

class ListecourseTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = { name: '', ings: {} };
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/ingredients?'
      url += 'label=ALL'
      fetch(url)
        .then(response => response.json())
        .then(result => {
          this.setState({ ings: result });
        }).catch(err => console.log(err))
    });
  }

  render() {
    return (
      <MaterialTable
        icons={tableIcons}
        title="Liste Course"
        columns={[
          { title: 'ID', field: 'id', filtering: false, hidden: true },
          {
            title: 'Nom', field: 'label', sorting: false, editable: 'onAdd',
            editComponent: propsIng => (
              <Autocomplete
                options={this.state.ings}
                getOptionLabel={option => option.label_fr}
                style={{ width: 300 }}
                onChange={(event, value) => {
                  if (value != null) {
                    propsIng.onChange(value.label_fr);
                  }
                }}
                renderInput={params => <TextField {...params} label={propsIng.value} variant="outlined" />}
              />
            )
          }
        ]}
        data={query =>
          new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_SRV_URL
            url += '/listecourse?'
            url += 'user=' + sessionStorage.getItem('user')
            fetch(url)
              .then(response => response.json())
              .then(result => {
                resolve({
                  data: result.rows,
                })
              })
          })
        }
        editable={{
          onRowAdd: query =>
            new Promise((resolve, reject) => {
              if (query.label === undefined) {
                reject();
              } else {
                let url = process.env.REACT_APP_SRV_URL
                url += '/listecourse/'
                fetch(url, {
                  method: 'POST',
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + sessionStorage.getItem('token'),
                  },
                  body: '{"user":"' + sessionStorage.getItem('user') + '","labelIng":"' + query.label + '"}'
                })
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                    })
                  })
              }
            }),
          onRowDelete: query =>
            new Promise((resolve, reject) => {
              let url = process.env.REACT_APP_SRV_URL
              url += '/listecourse/'
              fetch(url, {
                method: 'DELETE',
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  "Authorization": "Bearer " + sessionStorage.getItem('token'),
                },
                body: '{"user":"' + sessionStorage.getItem('user') + '","labelIng":"' + query.label + '"}'
              })
                .then(response => response.json())
                .then(result => {
                  resolve({
                  })
                })
            }),
        }}
        options={{
          search: false,
          filtering: false,
          sorting: false,
          paging: false,
          rowStyle: x => {
            if (x.tableData.id % 2) {
              return { fontSize: 16, backgroundColor: "#f2f2f2" }
            } else {
              return { fontSize: 16 }
            }
          }
        }}
        actions={[
          {
            icon: Search,
            tooltip: 'Voir recettes',
            onClick: (event, rowData) => { window.location.href = "/recettes?ings=" + rowData.label }
          }
        ]}
      />
    )
  }
}
export default ListecourseTable;