import React from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Lock from '@material-ui/icons/Lock';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DownloadLink from './DownloadLink';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import logoandroid from '../images/android_logo.png';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Backspace from '@material-ui/icons/Backspace';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fabBottom: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Lock: Lock,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

class AdminTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openDialogEdit: false,
      openDialogAlert: false,
      password: '',
      repeatPassword: '',
      user: '',
      alertMessage: '',
      roles: ["users","admin"],
    };
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleOpenAlertDialog = this.handleOpenAlertDialog.bind(this);
    this.handleCloseAlertDialog = this.handleCloseAlertDialog.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.removeIngWithNoRecip = this.removeIngWithNoRecip.bind(this);
  }

  isRoleEditable() {
    return (sessionStorage.getItem('role') === "admin")
  }

  handleOpenDialog(username) {
    this.setState({
      openDialogEdit: true,
      user: username
    });
  }

  handleCloseDialog() {
    this.setState({
      openDialogEdit: false
    });
  }

  handleOpenAlertDialog() {
    this.setState({
      openDialogAlert: true,
    });
  }

  handleCloseAlertDialog() {
    this.setState({
      openDialogAlert: false
    });
  }

  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  componentDidMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule('isPasswordMatch');
  }

  handleSubmit = (event) => {
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/user/pwd'
      fetch(url, { method: 'PUT', headers: { "Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + sessionStorage.getItem('token') }, body: '{"nom":"' + this.state.user + '","mdp":"' + this.state.password + '"}' })
        .then(response => response.json())
        .then(result => {
          this.handleCloseDialog();
          this.setState({ alertMessage: result.status });
          this.handleOpenAlertDialog();
        }).catch(err => console.log(err))
    });
  }

  removeIngWithNoRecip() {
    new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SRV_URL
      url += '/procedures/deleteIngWithNoRef'
      fetch(url, { method: 'DELETE', headers: { "Authorization": "Bearer " + sessionStorage.getItem('token') } })
        .then(response => {
          console.log(response)
          this.setState({ alertMessage: response.statusText });
          this.handleOpenAlertDialog();
        }).catch(
          err => {
            this.setState({ alertMessage: err.message })
            this.handleOpenAlertDialog();
          })
    });
  }

  render() {
    return (
      <Container className={useStyles.container}>
        Download:<DownloadLink to="../download/RecettesManager-v4.2.1.apk" download="RecettesManager-v4.2.1.apk" target="_blank"><img src={logoandroid} alt="Logo" height="42" width="42" align="middle" /></DownloadLink >
        <br />
        <MaterialTable
          icons={tableIcons}
          title="Utilisateurs"
          columns={[
            { title: 'Nom', field: 'user', sorting: true },
            { title: 'Mail', field: 'adresseMail', sorting: false },
            { title: 'Role', field: 'role', sorting: false, editable: this.isRoleEditable,
              editComponent: propsRole => (
                <Autocomplete
                  options={this.state.roles}
                  getOptionLabel={option => option}
                  style={{ width: 300 }}
                  onChange={(event, value) => {
                    if (value != null) {
                      propsRole.onChange(value);
                    }
                  }}
                  renderInput={params => <TextField {...params} label={propsRole.value} variant="outlined" />}
                />
              ) },
            { title: 'Langue', field: 'lang', sorting: false },
            { title: 'Source', field: 'source', editable: 'never' },
            { title: 'Première connexion', field: 'firstconn', editable: 'never' },
            { title: 'Dernière connexion', field: 'lastConn', editable: 'never' },
          ]}
          data={query =>
            new Promise((resolve, reject) => {
              let url = process.env.REACT_APP_SRV_URL
              url += '/users'
              url += '?rp=' + query.pageSize
              url += '&page=' + (query.page + 1)
              if (query.orderDirection) {
                url += '&sortorder=' + query.orderDirection
              }
              fetch(url)
                .then(response => response.json())
                .then(result => {
                  resolve({
                    data: result.rows,
                    page: result.page - 1,
                    totalCount: result.count,
                  })
                })
            })
          }
          editable={{
            isDeletable: rowData => (sessionStorage.getItem('role') === "admin"),
            isEditable: rowData => (rowData.user === sessionStorage.getItem('user') || (sessionStorage.getItem('role') === "admin")),
            onRowAdd: query =>
              new Promise((resolve, reject) => {
                let url = process.env.REACT_APP_SRV_URL
                url += '/user'
                fetch(url, {
                  method: 'POST',
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + sessionStorage.getItem('token')
                  },
                  body: '{"mdp":"recemptymdp","nom":"' + query.user + '","mail":"' + query.adresseMail + '","lang":"' + query.lang + '","role":"' + query.role + '"}'
                })
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                    })
                  })
              }),
            onRowUpdate: query =>
              new Promise((resolve, reject) => {
                if (query.user === undefined || query.adresseMail === undefined || query.lang === undefined ) {
                  reject();
                } else {
                  let url = process.env.REACT_APP_SRV_URL
                  url += '/user/upd'
                  fetch(url, {
                    method: 'PUT',
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "Authorization": "Bearer " + sessionStorage.getItem('token')
                    },
                    body: '{"nom":"' + query.user + '","mail":"' + query.adresseMail + '","lang":"' + query.lang + '","role":"' + query.role + '"}'
                  })
                    .then(response => response.json())
                    .then(result => {
                      resolve({
                      })
                    })
                }
              }),
            onRowDelete: query =>
              new Promise((resolve, reject) => {
                let url = process.env.REACT_APP_SRV_URL
                url += '/user/'
                url += query.user
                fetch(url, { method: 'DELETE', headers: { "Authorization": "Bearer " + sessionStorage.getItem('token') } })
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                    })
                  })
              })
          }}
          options={{
            search: false,
            filtering: false,
            sorting: true,
            toolbar: this.isRoleEditable(),
            rowStyle: x => {
              if (x.tableData.id % 2) {
                return { fontSize: 16, backgroundColor: "#f2f2f2" }
              } else {
                return { fontSize: 16 }
              }
            }
          }}
          actions={[
            rowData => ({
              icon: Lock,
              tooltip: 'Change password',
              onClick: (event, rowData) => this.handleOpenDialog(rowData.user),
              disabled: (rowData.user !== sessionStorage.getItem('user') && (sessionStorage.getItem('role') !== "admin"))
            })
          ]}
        />
        <Dialog id={"dialogComment"} open={this.state.openDialogEdit} onClose={this.handleOpenDialog} TransitionComponent={Transition} >
          <div style={{ width: 500 }}>
            <DialogTitle id="customized-dialog-title" >
              Change password
            </DialogTitle>
            <ValidatorForm className={useStyles.root} ref="form" onSubmit={this.handleSubmit} autoComplete="off" onError={errors => console.log(errors)}>
              <DialogContent dividers>
                <div>
                  <TextValidator
                    label="Password"
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    validators={['required', 'minStringLength:6']}
                    errorMessages={['this field is required', '6 charactères minimum']}
                    value={this.state.password}
                  />
                  <TextValidator
                    label="Repeat password"
                    onChange={this.handleChange}
                    name="repeatPassword"
                    type="password"
                    validators={['isPasswordMatch', 'required']}
                    errorMessages={['password mismatch', 'this field is required']}
                    value={this.state.repeatPassword}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDialog} color="primary" variant="contained" startIcon={<DeleteIcon />}>
                  Annuler
                </Button>
                <Button type="submit" color="primary" variant="contained" startIcon={<SaveIcon />}>
                  Valider
                </Button>
              </DialogActions>
            </ValidatorForm>
          </div>
        </Dialog>
        <div>
          <br />
          <Button onClick={this.removeIngWithNoRecip} color="primary" variant="contained" startIcon={<Backspace />}>
            Delete ingredient with no recipes
          </Button>
        </div>
        <Dialog open={this.state.openDialogAlert} onClose={this.handleCloseAlertDialog} >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alertMessage}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Container>
    )
  }
}
export default AdminTable;