import React from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const tableIcons = {
    Add: AddBox,
    Check: Check,
    Clear: Clear,
    Delete: DeleteOutline,
    DetailPanel: ChevronRight,
    Edit: Edit,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search: Search,
    SortArrow: ArrowUpward,
    ThirdStateCheck: Remove,
};

class RecetteLInks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noms: [],
            idToAdd: ''
        }
        new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_SRV_URL
            url += '/recettes/noms'
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    this.setState({ noms: result.rows });
                }).catch(err => console.log(err))
        });
    }
    render() {
        return (
            <div>
            <MaterialTable
                icons={tableIcons}
                title="Recettes liées:"
                columns={[
                    { title: 'ID', field: 'idrecette', filtering: false, sorting: false, hidden: true },
                    { title: 'source', field: 'source', filtering: false, sorting: false, hidden: true },
                    { title: 'Nom', field: 'nom', sorting: false,editable: 'onAdd',
                        editComponent: propsNom => (
                          <Autocomplete
                            options={this.state.noms}
                            getOptionLabel={option => option.nom}
                            style={{ width: 300 }}
                            onChange={(event, value) => {
                              if (value != null) {
                                propsNom.onChange(value.nom);
                                this.setState({ idToAdd: value.idrecette });
                              }
                            }}
                            renderInput={params => <TextField {...params} label={propsNom.value} variant="outlined" />}
                          />
                        ),
                        render: rowData => {
                            return unescape(rowData.nom)
                        }
                    },
                ]}
                data={query =>
                    new Promise((resolve, reject) => {
                        let url = process.env.REACT_APP_SRV_URL
                        url += '/recettes/link/'
                        url += this.props.id
                        fetch(url)
                            .then(response => response.json())
                            .then(result => {
                                resolve({
                                    data: result.rows,
                                    totalCount: result.count,
                                })
                            })
                    })
                }
                editable={{
                    isDeletable: rowData => rowData.source === 1,
                    onRowDelete: query =>
                      new Promise((resolve, reject) => {
                        let url = process.env.REACT_APP_SRV_URL
                        url += '/recettes/link/'
                        url += this.props.id
                        url += '?linkid='
                        url += query.idrecette
                        fetch(url, { method: 'DELETE', headers: { "Authorization": "Bearer "+sessionStorage.getItem('token') }  })
                          .then(response => response.json())
                          .then(result => {
                            resolve({
                            })
                          })
                      }),
                      onRowAdd: query =>
                        new Promise((resolve, reject) => {
                            let url = process.env.REACT_APP_SRV_URL
                            url += '/recettes/link/'
                            url += this.props.id
                            url += '?linkid='
                            url += this.state.idToAdd
                            fetch(url, { method: 'PUT',
                                headers: {"Content-type": "application/json; charset=UTF-8",
                                    "Authorization": "Bearer "+sessionStorage.getItem('token'),
                                }})
                            .then(response => response.json())
                            .then(result => {
                                resolve({
                                })
                            })
                        }),
                  }}
                options={{
                    search: false,
                    filtering: false,
                    sorting: false,
                    paging: false,
                    showTitle: true,
                    toolbar: true,
                    headerStyle: {
                        backgroundColor: '#115293',
                        color: '#FFF',
                    }
                }}
                actions={[
                    {
                        icon: Search,
                        tooltip: 'Voir Recette',
                        onClick: (event, rowData) => {
                            let url = "/recettes/recette?page=recettes&idrecette=" + rowData.idrecette
                            window.location.href = url
                        }
                    }
                ]}
            />
            </div>
        )
    }
}
export default RecetteLInks;