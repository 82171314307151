import React, { Component } from 'react';
import '../css/planning.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';


const styles = {
    card: {
        position: 'relative',
        color: 'blue',
        borderStyle: 'outset',
        borderColor: 'black'
    }
}

class Planning extends Component {

    constructor(props) {
        super(props);
        this.state = {
            todos: [],
            lundiTasks: [],
            mardiTasks: [],
            mercrediTasks: [],
            jeudiTasks: [],
            vendrediTasks: [],
            samediTasks: [],
            dimancheTasks: [],
            draggedTask: {}
        }
        new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_SRV_URL
            url += '/planning?'
            url += 'user=' + sessionStorage.getItem('user')
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    this.setState({ todos: result.status.stock });
                    this.setState({ lundiTasks: result.status.lundi });
                    this.setState({ mardiTasks: result.status.mardi });
                    this.setState({ mercrediTasks: result.status.mercredi });
                    this.setState({ jeudiTasks: result.status.jeudi });
                    this.setState({ vendrediTasks: result.status.vendredi });
                    this.setState({ samediTasks: result.status.samedi });
                    this.setState({ dimancheTasks: result.status.dimanche });
                    this.setState({ draggedTask: {} });
                }).catch(err => console.log(err))
        });
    }

    viewRecette(idrec) {
        window.location.href="/recettes/recette?page=planning&idrecette="+idrec
    }

    moveRecOnBase(idrec, position) {
        new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_SRV_URL
            url += '/planning?'
            url += 'user=' + sessionStorage.getItem('user')
            url += '&position=' + position
            url += '&id=' + idrec
            fetch(url, { method: 'POST', headers: { "Authorization": "Bearer "+sessionStorage.getItem('token') } })
                .then(response => response.json())
                .then(result => {
                }).catch(err => console.log(err))
        });
    }
    deleteRecOnBase(task, position) {
        console.log('del rec:' + task.recid)
        new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_SRV_URL
            url += '/planning?'
            url += 'user=' + sessionStorage.getItem('user')
            url += '&position=' + position
            url += '&id=' + task.recid
            fetch(url, { method: 'DELETE', headers: { "Authorization": "Bearer "+sessionStorage.getItem('token') } })
                .then(response => response.json())
                .then(result => {
                    new Promise((resolve, reject) => {
                        let url = process.env.REACT_APP_SRV_URL
                        url += '/planning?'
                        url += 'user=' + sessionStorage.getItem('user')
                        fetch(url)
                            .then(response => response.json())
                            .then(result => {
                                this.setState({ todos: result.status.stock });
                                this.setState({ lundiTasks: result.status.lundi });
                                this.setState({ mardiTasks: result.status.mardi });
                                this.setState({ mercrediTasks: result.status.mercredi });
                                this.setState({ jeudiTasks: result.status.jeudi });
                                this.setState({ vendrediTasks: result.status.vendredi });
                                this.setState({ samediTasks: result.status.samedi });
                                this.setState({ dimancheTasks: result.status.dimanche });
                                this.setState({ draggedTask: {} });
                                this.forceUpdate();
                            }).catch(err => console.log(err))
                    });
                    this.forceUpdate();
                }).catch(err => console.log(err))
        });
    }

    onDrag = (event, todo) => {
        event.dataTransfer.setData('id', todo);
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.dropEffect = "move";
        this.setState({
            draggedTask: todo
        });
    }
    onDragOver = (event) => {
        event.preventDefault();
    }
    onDropTodo = (event) => {
        const { lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks, draggedTask, todos } = this.state;
        this.setState({
            todos: [...todos, draggedTask],
            lundiTasks: lundiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mercrediTasks: mercrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            mardiTasks: mardiTasks.filter(task => task.idpl !== draggedTask.idpl),
            jeudiTasks: jeudiTasks.filter(task => task.idpl !== draggedTask.idpl),
            vendrediTasks: vendrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            samediTasks: samediTasks.filter(task => task.idpl !== draggedTask.idpl),
            dimancheTasks: dimancheTasks.filter(task => task.idpl !== draggedTask.idpl),
            draggedTask: {},
        });
        this.moveRecOnBase(draggedTask.recid, "stock");
    }
    onDropLundi = (event) => {
        const { lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks, draggedTask, todos } = this.state;
        this.setState({
            lundiTasks: [...lundiTasks, draggedTask],
            todos: todos.filter(task => task.idpl !== draggedTask.idpl),
            mercrediTasks: mercrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            mardiTasks: mardiTasks.filter(task => task.idpl !== draggedTask.idpl),
            jeudiTasks: jeudiTasks.filter(task => task.idpl !== draggedTask.idpl),
            vendrediTasks: vendrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            samediTasks: samediTasks.filter(task => task.idpl !== draggedTask.idpl),
            dimancheTasks: dimancheTasks.filter(task => task.idpl !== draggedTask.idpl),
            draggedTask: {},
        });
        this.moveRecOnBase(draggedTask.recid, "lundi");
    }
    onDropMardi = (event) => {
        const { lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks, draggedTask, todos } = this.state;
        this.setState({
            mardiTasks: [...mardiTasks, draggedTask],
            todos: todos.filter(task => task.idpl !== draggedTask.idpl),
            lundiTasks: lundiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mercrediTasks: mercrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            jeudiTasks: jeudiTasks.filter(task => task.idpl !== draggedTask.idpl),
            vendrediTasks: vendrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            samediTasks: samediTasks.filter(task => task.idpl !== draggedTask.idpl),
            dimancheTasks: dimancheTasks.filter(task => task.idpl !== draggedTask.idpl),
            draggedTask: {},
        });
        this.moveRecOnBase(draggedTask.recid, "mardi");
    }
    onDropMercredi = (event) => {
        const { lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks, draggedTask, todos } = this.state;
        this.setState({
            mercrediTasks: [...mercrediTasks, draggedTask],
            todos: todos.filter(task => task.idpl !== draggedTask.idpl),
            lundiTasks: lundiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mardiTasks: mardiTasks.filter(task => task.idpl !== draggedTask.idpl),
            jeudiTasks: jeudiTasks.filter(task => task.idpl !== draggedTask.idpl),
            vendrediTasks: vendrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            samediTasks: samediTasks.filter(task => task.idpl !== draggedTask.idpl),
            dimancheTasks: dimancheTasks.filter(task => task.idpl !== draggedTask.idpl),
            draggedTask: {},
        });
        this.moveRecOnBase(draggedTask.recid, "mercredi");
    }
    onDropJeudi = (event) => {
        const { lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks, draggedTask, todos } = this.state;
        this.setState({
            jeudiTasks: [...jeudiTasks, draggedTask],
            todos: todos.filter(task => task.idpl !== draggedTask.idpl),
            lundiTasks: lundiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mardiTasks: mardiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mercrediTasks: mercrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            vendrediTasks: vendrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            samediTasks: samediTasks.filter(task => task.idpl !== draggedTask.idpl),
            dimancheTasks: dimancheTasks.filter(task => task.idpl !== draggedTask.idpl),
            draggedTask: {},
        });
        this.moveRecOnBase(draggedTask.recid, "jeudi");
    }
    onDropVendredi = (event) => {
        const { lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks, draggedTask, todos } = this.state;
        this.setState({
            vendrediTasks: [...vendrediTasks, draggedTask],
            todos: todos.filter(task => task.idpl !== draggedTask.idpl),
            lundiTasks: lundiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mardiTasks: mardiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mercrediTasks: mercrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            jeudiTasks: jeudiTasks.filter(task => task.idpl !== draggedTask.idpl),
            samediTasks: samediTasks.filter(task => task.idpl !== draggedTask.idpl),
            dimancheTasks: dimancheTasks.filter(task => task.idpl !== draggedTask.idpl),
            draggedTask: {},
        });
        this.moveRecOnBase(draggedTask.recid, "vendredi");
    }
    onDropSamedi = (event) => {
        const { lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks, draggedTask, todos } = this.state;
        this.setState({
            samediTasks: [...samediTasks, draggedTask],
            todos: todos.filter(task => task.idpl !== draggedTask.idpl),
            lundiTasks: lundiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mardiTasks: mardiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mercrediTasks: mercrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            jeudiTasks: jeudiTasks.filter(task => task.idpl !== draggedTask.idpl),
            vendrediTasks: vendrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            dimancheTasks: dimancheTasks.filter(task => task.idpl !== draggedTask.idpl),
            draggedTask: {},
        });
        this.moveRecOnBase(draggedTask.recid, "samedi");
    }
    onDropDimanche = (event) => {
        const { lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks, draggedTask, todos } = this.state;
        this.setState({
            dimancheTasks: [...dimancheTasks, draggedTask],
            todos: todos.filter(task => task.idpl !== draggedTask.idpl),
            lundiTasks: lundiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mardiTasks: mardiTasks.filter(task => task.idpl !== draggedTask.idpl),
            mercrediTasks: mercrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            jeudiTasks: jeudiTasks.filter(task => task.idpl !== draggedTask.idpl),
            vendrediTasks: vendrediTasks.filter(task => task.idpl !== draggedTask.idpl),
            samediTasks: samediTasks.filter(task => task.idpl !== draggedTask.idpl),
            draggedTask: {},
        });
        this.moveRecOnBase(draggedTask.recid, "dimanche");
    }
    render() {
        const { todos, lundiTasks, mardiTasks, mercrediTasks, jeudiTasks, vendrediTasks, samediTasks, dimancheTasks } = this.state;
        return (
            <div className="App">
                <div
                    onDrop={event => this.onDropTodo(event)}
                    onDragOver={event => this.onDragOver(event)}
                    className="stock"
                >
                    <h4>Stock</h4>
                    {
                        todos.map(todo =>
                            <div
                                key={todo.idpl}
                                draggable="true"
                                onDragStart={(event) => this.onDrag(event, todo)}
                            >
                                <Card style={styles.card} >
                                    <CardContent>
                                        <Typography>
                                            {todo.nom}
                                        </Typography>
                                    </CardContent>
                                    <IconButton aria-label="voir" onClick={(event) => this.viewRecette(todo.recid)}>
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton aria-label="supprimer" onClick={(event) => this.deleteRecOnBase(todo, "stock")}>
                                        <CancelIcon />
                                    </IconButton>
                                </Card>
                            </div>)
                    }
                </div>
                <div
                    onDrop={event => this.onDropLundi(event)}
                    onDragOver={event => this.onDragOver(event)}
                    className="lundi"
                >
                    <h4>Lundi</h4>
                    {lundiTasks.map((task, index) =>
                        <div
                            key={task.idpl}
                            draggable="true"
                            onDragStart={(event) => this.onDrag(event, task)}
                        >
                            <Card style={styles.card}  >
                                <CardContent>
                                    <Typography>
                                        {task.nom}
                                    </Typography>
                                </CardContent>
                                <div>
                                    <IconButton aria-label="voir" onClick={(event) => this.viewRecette(task.recid)}>
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton aria-label="supprimer" onClick={(event) => this.deleteRecOnBase(task, "lundi")}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            </Card>
                        </div>
                    )}
                </div>
                <div
                    onDrop={event => this.onDropMardi(event)}
                    onDragOver={event => this.onDragOver(event)}
                    className="mardi"
                >
                    <h4>Mardi</h4>
                    {mardiTasks.map((task, index) =>
                        <div
                            key={task.idpl}
                            draggable="true"
                            onDragStart={(event) => this.onDrag(event, task)}
                        >
                            <Card style={styles.card}  >
                                <CardContent>
                                    <Typography>
                                        {task.nom}
                                    </Typography>
                                </CardContent>
                                <IconButton aria-label="voir" onClick={(event) => this.viewRecette(task.recid)}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="supprimer" onClick={(event) => this.deleteRecOnBase(task, "mardi")}>
                                    <CancelIcon />
                                </IconButton>
                            </Card>
                        </div>
                    )}
                </div>
                <div
                    onDrop={event => this.onDropMercredi(event)}
                    onDragOver={event => this.onDragOver(event)}
                    className="mercredi"
                >
                    <h4>Mercredi</h4>
                    {mercrediTasks.map((task, index) =>
                        <div
                            key={task.idpl}
                            draggable="true"
                            onDragStart={(event) => this.onDrag(event, task)}
                        >
                            <Card style={styles.card}  >
                                <CardContent>
                                    <Typography>
                                        {task.nom}
                                    </Typography>
                                </CardContent>
                                <IconButton aria-label="voir" onClick={(event) => this.viewRecette(task.recid)}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="supprimer" onClick={(event) => this.deleteRecOnBase(task, "mercredi")}>
                                    <CancelIcon />
                                </IconButton>
                            </Card>
                        </div>
                    )}
                </div>
                <div
                    onDrop={event => this.onDropJeudi(event)}
                    onDragOver={event => this.onDragOver(event)}
                    className="jeudi"
                >
                    <h4>Jeudi</h4>
                    {jeudiTasks.map((task, index) =>
                        <div
                            key={task.idpl}
                            draggable="true"
                            onDragStart={(event) => this.onDrag(event, task)}
                        >
                            <Card style={styles.card}  >
                                <CardContent>
                                    <Typography>
                                        {task.nom}
                                    </Typography>
                                </CardContent>
                                <IconButton aria-label="voir" onClick={(event) => this.viewRecette(task.recid)}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="supprimer" onClick={(event) => this.deleteRecOnBase(task, "jeudi")}>
                                    <CancelIcon />
                                </IconButton>
                            </Card>
                        </div>
                    )}
                </div>
                <div
                    onDrop={event => this.onDropVendredi(event)}
                    onDragOver={event => this.onDragOver(event)}
                    className="vendredi"
                >
                    <h4>Vendredi</h4>
                    {vendrediTasks.map((task, index) =>
                        <div
                            key={task.idpl}
                            draggable="true"
                            onDragStart={(event) => this.onDrag(event, task)}
                        >
                            <Card style={styles.card}  >
                                <CardContent>
                                    <Typography>
                                        {task.nom}
                                    </Typography>
                                </CardContent>
                                <IconButton aria-label="voir" onClick={(event) => this.viewRecette(task.recid)}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="supprimer" onClick={(event) => this.deleteRecOnBase(task, "vendredi")}>
                                    <CancelIcon />
                                </IconButton>
                            </Card>
                        </div>
                    )}
                </div>
                <div
                    onDrop={event => this.onDropSamedi(event)}
                    onDragOver={event => this.onDragOver(event)}
                    className="samedi"
                >
                    <h4>Samedi</h4>
                    {samediTasks.map((task, index) =>
                        <div
                            key={task.idpl}
                            draggable="true"
                            onDragStart={(event) => this.onDrag(event, task)}
                        >
                            <Card style={styles.card}  >
                                <CardContent>
                                    <Typography>
                                        {task.nom}
                                    </Typography>
                                </CardContent>
                                <IconButton aria-label="voir" onClick={(event) => this.viewRecette(task.recid)}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="supprimer" onClick={(event) => this.deleteRecOnBase(task, "samedi")}>
                                    <CancelIcon />
                                </IconButton>
                            </Card>
                        </div>
                    )}
                </div>
                <div
                    onDrop={event => this.onDropDimanche(event)}
                    onDragOver={event => this.onDragOver(event)}
                    className="dimanche"
                >
                    <h4>Dimanche</h4>
                    {dimancheTasks.map((task, index) =>
                        <div
                            key={task.idpl}
                            draggable="true"
                            onDragStart={(event) => this.onDrag(event, task)}
                        >
                            <Card style={styles.card}  >
                                <CardContent>
                                    <Typography>
                                        {task.nom}
                                    </Typography>
                                </CardContent>
                                <IconButton aria-label="voir" onClick={(event) => this.viewRecette(task.recid)}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="supprimer" onClick={(event) => this.deleteRecOnBase(task, "dimanche")}>
                                    <CancelIcon />
                                </IconButton>
                            </Card>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Planning;

