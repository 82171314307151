import React from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import {unescape} from 'html-escaper';

const tableIcons = {
    Add: AddBox,
    Check: Check,
    Clear: Clear,
    Delete: DeleteOutline,
    DetailPanel: ChevronRight,
    Edit: Edit,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search: Search,
    SortArrow: ArrowUpward,
    ThirdStateCheck: Remove,
  };


class Recette extends React.Component {
        
    render() {
        return (<React.Fragment>
            <div id={"divrec" + this.props.id}> <TextField
                id={"name" + this.props.id}
                label="Nom"
                value={this.props.nom}
                variant="outlined"
                fullWidth
                InputProps={{
                    readOnly: true,
                }}
            /></div>
            <div ><table><tbody><tr><td><TextField
                id={"temps" + this.props.id}
                label="Temps"
                value={this.props.temps}
                variant="outlined"
                margin="normal"
                InputProps={{
                    readOnly: true,
                }}
            /></td><td><TextField
                    id={"duree" + this.props.id}
                    label="Durée"
                    value={this.props.tempsGlobal}
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                /></td></tr></tbody></table></div>
            <MaterialTable
                icons={tableIcons}
                title="Ingredients:"
                columns={[
                    { title: 'Ingredient', field: 'ingredient',sorting: false,
                    render: rowData => {
                        return unescape(rowData.ingredient)
                        }
                      },
                    { title: 'Quantité', field: 'quantite',sorting: false,
                        render: rowData => {
                            return unescape(rowData.quantite)
                        }
                     }
                ]}
                data= {this.props.ingsListe}
                options={{
                    search: false,
                    filtering: false,
                    sorting: false,
                    paging:false,
                    showTitle:false,
                    toolbar:false,
                    rowStyle: x => {
                        if (x.tableData.id % 2) {
                            return { fontSize: 16, backgroundColor: "#f2f2f2" }
                        } else {
                            return { fontSize: 16 }
                        }
                    },
                    headerStyle: {
                        backgroundColor: '#115293',
                        color: '#FFF',
                      }
                }}
            />
            <div><table><tbody><tr><td><TextField
                id={"source" + this.props.id}
                label="source"
                value={this.props.source}
                margin="normal"
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            /></td><td>
            <TextField
                id={"type" + this.props.id}
                label="Type"
                value={this.props.type}
                margin="normal"
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            /></td><td>
            <TextField
                id={"saison" + this.props.id}
                label="Saison"
                value={this.props.saison}
                margin="normal"
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
            /></td></tr></tbody></table></div>
            <div><FormControlLabel
                control={
                    <Checkbox
                        value="true"
                        color="primary"
                        disabled
                        checked={Boolean(this.props.achats)}
                    />
                }
                label="Achats"
            /></div>
            <div><TextField
                id={"description" + this.props.id}
                label="Description"
                multiline
                rowsMax="10"
                value={this.props.description}
                margin="normal"
                variant="outlined"
                InputProps={{
                    readOnly: true,
                }}
                fullWidth
            /></div>
        </React.Fragment>
        );
    }
}
export default Recette;