import React from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { escape } from 'html-escaper';

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

class IngredientsTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }
  render() {
    return (
      <MaterialTable
        icons={tableIcons}
        title="Ingredients"
        columns={[
          { title: 'ID', field: 'idingredients', filtering: false, hidden: true },
          { title: 'Nom (FR)', field: 'label_fr' },
          { title: 'Name (EN)', field: 'label_en', sorting: false },
          { title: 'Nome (IT)', field: 'label_it', sorting: false },
          { title: 'Nbr Recette', field: 'nombre', sorting: false, editable: 'never' },
        ]}
        data={query =>
          new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_SRV_URL
            url += '/ingredients/table?'
            url += '&rp=' + query.pageSize
            url += '&page=' + (query.page + 1)
            if (query.orderDirection) {
              url += '&sortorder=' + query.orderDirection
            }
            if (query.search) {
              url += '&rec=ALL'
              url += '&query=' + escape(encodeURIComponent(query.search))
            }
            fetch(url)
              .then(response => response.json())
              .then(result => {
                resolve({
                  data: result.rows,
                  page: result.page - 1,
                  totalCount: result.count,
                })
              })
          })
        }
        editable={{
          onRowAdd: query =>
            new Promise((resolve, reject) => {
              if (query.label_fr === undefined) {
                reject();
              } else {
                let url = process.env.REACT_APP_SRV_URL
                if (query.label_it === undefined) {
                  query.label_it = ''
                }
                if (query.label_en === undefined) {
                  query.label_en = ''
                }
                url += '/ingredients'
                fetch(url, {
                  method: 'PUT',
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + sessionStorage.getItem('token')
                  },
                  body: '{"label_fr":"' + escape(encodeURIComponent(query.label_fr)) + '","label_it":"' + escape(encodeURIComponent(query.label_it)) + '","label_en":"' + escape(encodeURIComponent(query.label_en)) + '"}'
                })
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                    })
                  })
              }
            }),
          onRowUpdate: query =>
            new Promise((resolve, reject) => {
              if (query.label_fr === undefined) {
                reject();
              } else {
                let url = process.env.REACT_APP_SRV_URL
                if (query.label_it === undefined) {
                  query.label_it = ''
                }
                if (query.label_en === undefined) {
                  query.label_en = ''
                }
                url += '/ingredients/'
                url += query.idingredients
                fetch(url, {
                  method: 'POST',
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + sessionStorage.getItem('token')
                  },
                  body: '{"label_fr":"' + escape(encodeURIComponent(query.label_fr)) + '","label_it":"' + escape(encodeURIComponent(query.label_it)) + '","label_en":"' + escape(encodeURIComponent(query.label_en)) + '"}'
                })
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                    })
                  })
              }
            })
        }}
        options={{
          search: true,
          filtering: false,
          sorting: true,
          rowStyle: x => {
            if (x.tableData.id % 2) {
              return { fontSize: 16, backgroundColor: "#f2f2f2" }
            } else {
              return { fontSize: 16 }
            }
          }
        }}
        actions={[
          {
            icon: Search,
            tooltip: 'Voir ingredients',
            onClick: (event, rowData) => { window.location.href = "/recettes?ings=" + rowData.label_fr }
          }
        ]}
      />
    )
  }
}
export default IngredientsTable;
